.emp-reuseable-table-search {
  display: flex;
  gap: 1rem;

  // justify-content: space-between;
  // margin-left: 2rem;

  .emp-search-box {
    width: 25rem;
    // align-items: center;
    background-color: var(--white-color);
    border-radius: 0.9rem;
    display: flex;
    // align-items: center;
    border: 0.1rem solid var(--border-color);
    // overflow: hidden;
    padding: 0rem 1rem;

    .input {
      border: none;
      flex: 1;
      padding: 1.2rem;

      &:focus {
        outline: none;
        border: none;
      }

      &::placeholder {
        color: #84919a;
      }
    }
    .label {
      flex: 0 0 8%;
      display: grid;
      place-items: center;
      .icon {
        color: #b0babf;
        position: relative;
        right: 1rem;
      }
    }
  }
  & > *:nth-child(5) {
    margin-right: auto;
    // justify-content: flex-start;
    // margin: unset;
    // float: left !important;
  }

  .emp-select-container {
    // display: flex;
    // gap: 1rem;
    // margin-left: auto;
    // justify-content: flex-end;
    // margin-right: auto;

    & > *:nth-child(1) {
      // margin-right: auto;
      justify-content: flex-end;

      // margin: unset;
      // float: left !important;
    }
  }
  // .emp-select-container {
  //   padding: 0rem 3rem;
  //   display: flex;

  // justify-content: space-evenly;
  // margin-right: 10rem;
  // gap: 2rem;
  .type-select-box {
    // margin-right: auto;
    // margin-left: 1rem;
    // position: relative;
    // width: 30rem;
    z-index: 10;

    // .select {
    //   & > *:nth-child(3) {
    //     // height: 1rem;
    //     // padding: 0rem 0.5rem;
    // font-size: 1.3rem;
    //     // width: 3rem;
    //   }

    & > * {
      font-size: 1.3rem;
      font-weight: 400;
    }
    // }
  }
  .select-filter-by {
    // width: 15rem;
    // color: #6e7c87;
    position: relative;
    // z-index: 10;

    & > *:nth-child(3) {
      // background-color: #e5e5e5;
      border-radius: 0.9rem;
      border: 0.1rem solid var(--border-color);
    }
  }
  //   }
  // }
  .emp-select {
    background-color: #e5e5e5;
    border-radius: 0.9rem;
    // display: flex;
    align-items: center;
    border: 0.1rem solid var(--border-color);
    // overflow: hidden;
    padding: 1.2rem 1rem;
    width: 15rem;
    color: #6e7c87;
    outline: none;
    border: none;

    &:focus {
      outline: none;
      border: none;
    }
  }
  .emp-card-btn {
    background-color: var(--blue-color);
    color: var(--white-color);
    font-weight: 600;
    width: 15rem;
    height: 3.6rem;
    margin-top: 0.2rem;
    display: flex;
    // width: 25rem;
    // height: 3.5rem;
    align-items: center;
    display: flex;
    font-size: 1.2rem;
    border-radius: 0.6rem;
    outline: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: all 0.3s;
    text-transform: capitalize;
    &:hover {
      transform: translateY(-0.1rem);
    }
    .icon {
      display: flex;
      gap: 1rem;
      margin-left: 1rem;
      // align-items: center;
    }
  }
  .emp-notify-bell-box {
    .img-box {
      width: 4rem;
      height: 4rem;
      // margin-right: 2rem;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: scale(1.05);
      }

      .img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }
}

// employee reusable table pointer
.emp-row-pointer {
  cursor: pointer;
}

.header-submit-filter {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: right;
  margin-right: 1rem;
  margin-bottom: -1rem;

  .select-filter-by {
    // width: 15rem;
    position: relative;
    // z-index: 10;

    & > *:nth-child(3) {
      // background-color: #e5e5e5;
      border-radius: 0.9rem;
      border: 0.1rem solid var(--border-color);
    }
    & > * {
      font-size: 1.3rem;
      font-weight: 400;
    }
    // color: #6e7c87;
  }
}
// filter-table
.emp-filter-wrap {
  width: 50rem;
  position: relative;
  overflow: auto;
  margin-bottom: 3rem;
  .title {
    font-weight: 600;
    border-bottom: 0.1rem solid var(--border-color);
    padding: 1rem 2rem;
  }
  .child-wrap {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 3rem;
    overflow-y: scroll;
    max-height: 80vh;
    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      background-color: #ffffff;
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: var(--blue-color);
    }
  }
  .btn-wrap {
    display: flex;
    gap: 2rem;
    justify-content: right;
    border-top: 0.1rem solid var(--border-color);
    padding: 0 3rem;

    .btn-box {
      align-items: center;
      display: flex;
      margin-top: 2rem;
    }
    .btn-sub-box {
      align-self: center;
      display: flex;
      margin-top: 2rem;
    }
  }
}

// table-check

.table-check[type="checkbox"] {
  // display: none;
  -webkit-appearance: none;
  background-color: #f6f8f9;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.3rem;
  border: 0.1rem solid var(--border-color);
  cursor: pointer;
  appearance: none;

  &::after {
    font-family: "Font Awsome 5 Free";
  }
}

// notify-btn
.emp-approval-notify {
  transition: all 0.3s;
  &:hover {
    transform: translateY(-0.2rem);
  }
}
