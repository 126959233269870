.notification-modal-wrap {
  height: 100vh;
  background-color: transparent !important;
  width: 100vw;
  position: fixed;
  z-index: 5000;
}
.notification-modal-wrap .trans-box {
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
}
.notification-modal-wrap .content-wrap {
  height: 100vh;
  width: 40rem;
  background-color: var(--bg-white-color);
  position: absolute;
  right: 0;
  top: 0;
  -webkit-animation: moveRightNotify 0.5s ease-in-out;
          animation: moveRightNotify 0.5s ease-in-out;
  color: var(--black-color);
  padding: 5rem 3rem 3rem 3rem;
  display: flex;
  flex-direction: column;
  z-index: 3;
}
.notification-modal-wrap .content-wrap .page-title {
  flex: 0 0 5%;
  font-weight: 800;
  font-size: 2rem;
}
.notification-modal-wrap .content-wrap .cancel-box {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.6rem;
  justify-self: flex-start;
  display: grid;
  place-items: center;
  border: 0.15rem solid #cc0905;
  cursor: pointer;
}
.notification-modal-wrap .content-wrap .cancel-box .icon {
  color: #cc0905;
}
.notification-modal-wrap .content-wrap .notify-wrap {
  padding: 0rem 2rem 2rem 0rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  flex: 1;
  overflow-y: scroll;
}
.notification-modal-wrap .content-wrap .notify-wrap::-webkit-scrollbar {
  display: none;
}
.notification-modal-wrap .content-wrap .notify-wrap .message-box {
  padding: 1rem 1rem;
  border: 0.1rem solid var(--blue-color);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.notification-modal-wrap .content-wrap .notify-wrap .message-box .message-title {
  font-weight: 600;
  font-size: 1.5rem;
  text-transform: capitalize;
}
.notification-modal-wrap .content-wrap .notify-wrap .message-box .message-text {
  font-size: 1.35rem;
  color: #676767;
}
.notification-modal-wrap .content-wrap .notify-wrap .message-box .messagebox-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.5rem;
}
.notification-modal-wrap .content-wrap .notify-wrap .message-box .date {
  font-size: 1.2rem;
  color: #676767;
  opacity: 0.5;
}
.notification-modal-wrap .content-wrap .notify-wrap .message-box .read {
  align-self: flex-end;
  padding: 0.7rem 2rem;
  border-radius: 1.5rem;
  background-color: #cccccc;
  font-size: 1rem;
  font-weight: 600;
  color: var(--blue-color);
  cursor: pointer;
  transition: all 0.3s;
}
.notification-modal-wrap .content-wrap .notify-wrap .message-box .read:hover {
  transform: translateY(-0.1rem);
  background-color: var(--blue-color);
  color: #ffffff;
}
.notification-modal-wrap .content-wrap .notify-wrap .message-box .read-active {
  background-color: var(--blue-color) !important;
  color: #ffffff;
}
.notification-modal-wrap .content-wrap .notify-wrap .message-box-inactive {
  opacity: 0.35;
}

@-webkit-keyframes moveRightNotify {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes moveRightNotify {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}/*# sourceMappingURL=NotificationModal.css.map */