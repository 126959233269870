.sidebar_menu_layout {
  width: 25rem;
  padding: 2rem 0rem;
  display: flex;
  flex-direction: column;
}
.sidebar_menu_layout .user-role-box {
  align-self: flex-start;
  margin-top: 1.5rem;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 1rem 1.2rem;
  border-radius: 4rem;
  background-color: rgba(255, 255, 255, 0.3);
  margin-left: 2rem;
  margin-bottom: -1.5rem;
}
.sidebar_menu_layout .user-role-box .text {
  font-size: 0.9rem;
  font-weight: 700;
  color: #ffffff;
}
.sidebar_menu_layout .logo-box {
  padding: 0rem 2rem;
}
.sidebar_menu_layout .logo-box .img-box {
  width: 11rem;
  height: 3rem;
}
.sidebar_menu_layout .logo-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.sidebar_menu_layout .logo-box .img-box-small {
  width: 4rem;
  height: 4rem;
  margin-left: -0.5rem;
}
.sidebar_menu_layout .list-box {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  color: #ffffff;
}
.sidebar_menu_layout .list-box .item {
  margin-bottom: 0.5rem;
}
.sidebar_menu_layout .list-box .item .parent-box {
  display: flex;
  align-items: center;
  padding: 1.3rem 2rem;
  cursor: pointer;
  margin-bottom: 1rem;
}
.sidebar_menu_layout .list-box .item .parent-box p {
  margin-right: auto;
  color: #ffffff;
  font-size: 1.2rem;
}
.sidebar_menu_layout .list-box .item .children-box {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  width: 100%;
  padding-right: 2rem;
}
.sidebar_menu_layout .list-box .item .children-box > *:not(:last-child) {
  margin-bottom: 1.5rem;
}
.sidebar_menu_layout .list-box .item .children-box .child {
  display: flex;
  align-items: center;
  margin-left: 2rem;
  padding: 0.7rem 1rem;
  cursor: pointer;
  border-radius: 0.7rem;
  position: relative;
}
.sidebar_menu_layout .list-box .item .children-box .child::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0.3rem;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0.7rem;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.3s, width 0.3s 0.3s;
}
.sidebar_menu_layout .list-box .item .children-box .child:hover::after {
  transform: scaleY(1);
  width: 100%;
}
.sidebar_menu_layout .list-box .item .children-box .child .img-box {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
}
.sidebar_menu_layout .list-box .item .children-box .child .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.sidebar_menu_layout .list-box .item .children-box .child .child-text {
  font-size: 1.3rem;
}
.sidebar_menu_layout .list-box .item .children-box .child:hover .child-text-reduce {
  transform: scale(1);
}
.sidebar_menu_layout .list-box .item .children-box .child .child-text-reduce {
  position: absolute;
  bottom: 120%;
  left: -1rem;
  font-size: 0.9rem;
  background-color: rgb(255, 255, 255);
  padding: 0.5rem;
  color: #1954a3;
  border-radius: 0.4rem;
  font-weight: 500;
  box-shadow: var(--box-shadow-two);
  transform: scale(0);
  transition: transform 0.5s;
  transform-origin: center bottom;
}
.sidebar_menu_layout .list-box .item .children-box .child-active {
  background-color: rgba(255, 255, 255, 0.3);
}
.sidebar_menu_layout .list-box .item .children-box .child-active::after {
  display: none;
}
.sidebar_menu_layout .list-box .item .children-box .child-active .child-text {
  font-weight: 700;
}
.sidebar_menu_layout .list-box .item .children-box .child-reduce {
  width: 4rem;
  padding: 0.6rem 0.4rem;
  display: grid;
  place-items: center;
  margin-left: 1.5rem;
}
.sidebar_menu_layout .list-box .item .children-box .child-reduce .img-box {
  margin-right: unset;
}
.sidebar_menu_layout .list-box .item .children-box-show {
  max-height: 20rem;
  transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
}
.sidebar_menu_layout .list-box .item-active .parent-box {
  background-color: rgba(255, 255, 255, 0.3);
}
.sidebar_menu_layout .list-box .item-reduce .parent-box {
  padding: 1.2rem 0.5rem;
}
.sidebar_menu_layout .list-box .item-reduce .parent-box p {
  font-size: 0.9rem;
  margin-right: 0.5rem;
}
.sidebar_menu_layout .list-box .item-reduce .drop-icon {
  width: 1rem;
  height: 1rem;
}
.sidebar_menu_layout .upgrade-box {
  margin-top: 3rem;
  padding: 2rem;
  align-self: center;
  border-radius: 0.7rem;
  background-color: rgba(255, 255, 255, 0.1);
  width: 80%;
  color: #ffffff;
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
}
.sidebar_menu_layout .upgrade-box .text {
  font-size: 1.2rem;
}
.sidebar_menu_layout .upgrade-box .upgrade {
  color: #fca549;
  margin-top: 2rem;
  cursor: pointer;
  align-self: flex-start;
  font-weight: 500;
}
.sidebar_menu_layout .upgrade-wrap-reduce {
  position: relative;
}
.sidebar_menu_layout .upgrade-wrap-reduce:hover .text {
  transform: scale(1);
}
.sidebar_menu_layout .upgrade-wrap-reduce .text {
  position: absolute;
  bottom: 90%;
  left: 1%;
  font-size: 0.9rem;
  background-color: rgb(255, 255, 255);
  padding: 0.5rem;
  color: #1954a3;
  border-radius: 0.4rem;
  font-weight: 500;
  box-shadow: var(--box-shadow-two);
  transform: scale(0);
  transition: transform 0.5s;
  transform-origin: center bottom;
}
.sidebar_menu_layout .upgrade-wrap-reduce .upgrade-box-reduce {
  width: 4rem;
  height: 4rem;
  color: #fca549;
  margin: 3rem 0rem 0rem 1.5rem;
  border-radius: 0.7rem;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 1rem;
  cursor: pointer;
}
.sidebar_menu_layout .switch-btn {
  display: flex;
  align-items: center;
  align-self: center;
  background-color: var(--bg-white-color);
  padding: 0.6rem 1rem;
  color: #1954a3;
  border: none;
  outline: none;
  border-radius: 2rem;
  margin-top: 3rem;
  cursor: pointer;
  color: var(--black-color);
}
.sidebar_menu_layout .switch-btn:hover .icon {
  transform: translateX(0.5rem);
}
.sidebar_menu_layout .switch-btn .text {
  margin-right: 1rem;
  font-size: 1.2rem;
}
.sidebar_menu_layout .switch-btn .icon {
  width: 1rem;
  height: 1rem;
  transition: all 0.3s;
}
.sidebar_menu_layout .switch-btn-wrap-reduce {
  position: relative;
}
.sidebar_menu_layout .switch-btn-wrap-reduce .switch-btn-reduce {
  margin-left: 2rem;
  width: 3rem;
  height: 3rem;
  color: #1954a3;
  margin-top: 3rem;
  cursor: pointer;
  background-color: var(--bg-white-color);
  padding: 0.6rem;
  border-radius: 0.8rem;
}
.sidebar_menu_layout .switch-btn-wrap-reduce:hover .text {
  transform: scale(1);
}
.sidebar_menu_layout .switch-btn-wrap-reduce .text {
  position: absolute;
  bottom: 80%;
  left: 1.5%;
  font-size: 0.9rem;
  background-color: rgb(255, 255, 255);
  padding: 0.5rem;
  color: #1954a3;
  border-radius: 0.4rem;
  font-weight: 500;
  box-shadow: var(--box-shadow-two);
  transform: scale(0);
  transition: transform 0.5s;
  transform-origin: center bottom;
  width: 5.5rem;
}/*# sourceMappingURL=SidebarMenu.css.map */