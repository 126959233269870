.overview-card-modal-wrap {
  min-width: 40rem;
  min-height: 60rem;
  max-height: 80vh;
  background-color: var(--bg-white-color);
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  overflow-y: scroll;
  padding-bottom: 3rem;
}
.overview-card-modal-wrap .top-box {
  background-color: #ffffff;
  height: 6rem;
  border-bottom: 0.1rem solid var(--border-color);
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
}
.overview-card-modal-wrap .top-box .title-box {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-right: auto;
}
.overview-card-modal-wrap .top-box .title-box .title {
  font-size: 1.35rem;
  font-weight: 600;
  color: var(--black-color);
  margin-right: auto;
  cursor: pointer;
  position: relative;
}
.overview-card-modal-wrap .top-box .title-box .title::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0.25rem;
  background-color: var(--blue-color);
  border-radius: 1rem;
  visibility: hidden;
  transform: translateY(1rem);
}
.overview-card-modal-wrap .top-box .title-box .title-active::after {
  visibility: visible;
}
.overview-card-modal-wrap .top-box .ellipse-box {
  margin: 0rem 2.5rem;
  position: relative;
}
.overview-card-modal-wrap .top-box .ellipse-box .icon {
  cursor: pointer;
}
.overview-card-modal-wrap .top-box .ellipse-box .action-box {
  position: absolute;
  top: 100%;
  right: 0;
  height: 12rem;
  width: 15rem;
  border-radius: 1rem;
  background-color: var(--bg-white-color);
  box-shadow: var(--box-shadow-two);
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: 1fr;
  padding: 0.5rem 1.5rem;
  font-size: 1.3rem;
  align-self: center;
  transform: scale(0);
  transition: all 0.3s;
  transform-origin: top right;
}
.overview-card-modal-wrap .top-box .ellipse-box .action-box > *:first-child {
  color: #cc0905;
}
.overview-card-modal-wrap .top-box .ellipse-box .action-box > *:not(:last-child) {
  border-bottom: 0.1rem solid var(--border-color);
}
.overview-card-modal-wrap .top-box .ellipse-box .action-box > * {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.overview-card-modal-wrap .top-box .ellipse-box .action-box > * span {
  display: inline-block;
}
.overview-card-modal-wrap .top-box .ellipse-box .action-box-active {
  transform: scale(1);
}
.overview-card-modal-wrap .top-box .btn-blue-wrap {
  padding: 0.9rem 2rem;
}
.overview-card-modal-wrap .top-box .cancel-box {
  background-color: #ffefeb;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.overview-card-modal-wrap .top-box .cancel-box .icon {
  color: #cc0905;
}
.overview-card-modal-wrap .bottom-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0rem 2rem;
  border-top: 0.1rem solid var(--border-color);
  padding-top: 1.5rem;
  background-color: rgba(255, 255, 255, 0.5);
}
.overview-card-modal-wrap .bottom-box > *:first-child {
  margin-right: 2rem;
  background-color: var(--bg-white-color);
  border: 0.1rem solid var(--blue-color);
  color: var(--blue-color);
}
.overview-card-modal-wrap .bottom-box-two {
  background-color: var(--bg-white-color);
  border-top: 0.1rem solid var(--border-color);
  padding-top: 1.5rem;
  padding: 2rem;
  flex: 1;
  margin-top: -2rem;
  display: flex;
  flex-direction: column;
  max-height: 45rem;
  overflow-y: scroll;
  padding-top: 3rem;
  display: none;
  overflow-y: scroll;
}
.overview-card-modal-wrap .bottom-box-two > *:not(:last-child) {
  margin-bottom: 2rem;
}
.overview-card-modal-wrap .bottom-box-two::-webkit-scrollbar {
  width: 0.5rem;
}
.overview-card-modal-wrap .bottom-box-two::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 10px;
}
.overview-card-modal-wrap .bottom-box-two::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--blue-color);
}
.overview-card-modal-wrap .bottom-box-two .comment-wrap {
  width: 100%;
  background-color: var(--bg-white-color);
  border-radius: 0.8rem;
  padding: 2rem 2.5rem;
  display: flex;
  box-shadow: var(--box-shadow-one);
}
.overview-card-modal-wrap .bottom-box-two .comment-wrap .img-box {
  width: 3rem;
  height: 3rem;
}
.overview-card-modal-wrap .bottom-box-two .comment-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.overview-card-modal-wrap .bottom-box-two .comment-wrap .box-wrap {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.overview-card-modal-wrap .bottom-box-two .comment-wrap .box-wrap .top-box-wrap {
  display: flex;
}
.overview-card-modal-wrap .bottom-box-two .comment-wrap .box-wrap .top-box-wrap .box {
  font-size: 1.3rem;
  margin-right: auto;
}
.overview-card-modal-wrap .bottom-box-two .comment-wrap .box-wrap .top-box-wrap .box .text {
  color: #5b6871;
  margin-bottom: 0.8rem;
}
.overview-card-modal-wrap .bottom-box-two .comment-wrap .box-wrap .top-box-wrap .box .value {
  color: var(--black-color);
  font-weight: 600;
}
.overview-card-modal-wrap .bottom-box-two .comment-wrap .box-wrap .top-box-wrap .reject-box {
  background-color: #ffefeb;
  color: #cc0905;
  padding: 0.7rem 2.5rem;
  border-radius: 0.8rem;
  margin-right: 3rem;
}
.overview-card-modal-wrap .bottom-box-two .comment-wrap .box-wrap .top-box-wrap .reject-box p {
  text-transform: capitalize;
  font-weight: 500;
}
.overview-card-modal-wrap .bottom-box-two .comment-wrap .box-wrap .top-box-wrap .pending {
  background-color: rgba(252, 165, 73, 0.1);
  color: #fca549;
}
.overview-card-modal-wrap .bottom-box-two .comment-wrap .box-wrap .top-box-wrap .approved {
  background-color: rgba(7, 162, 135, 0.1);
  color: #07a287;
}
.overview-card-modal-wrap .bottom-box-two .comment-wrap .box-wrap .top-box-wrap .delete-icon {
  display: inline-block;
  cursor: pointer;
  align-self: center;
}
.overview-card-modal-wrap .bottom-box-two .comment-wrap .box-wrap .comment {
  margin-top: 2rem;
  color: #5b6871;
}
.overview-card-modal-wrap .content-box {
  background-color: var(--bg-white-color);
  flex: 1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  max-width: 45rem;
  overflow-y: scroll;
}
.overview-card-modal-wrap .content-box::-webkit-scrollbar {
  width: 0.5rem;
}
.overview-card-modal-wrap .content-box::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 10px;
}
.overview-card-modal-wrap .content-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--blue-color);
}
.overview-card-modal-wrap .content-box .activity-log-wrap-box {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  flex: 1;
  overflow-y: scroll;
}
.overview-card-modal-wrap .content-box .activity-log-wrap-box::-webkit-scrollbar {
  width: 0.5rem;
}
.overview-card-modal-wrap .content-box .activity-log-wrap-box::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 10px;
}
.overview-card-modal-wrap .content-box .activity-log-wrap-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--blue-color);
}
.overview-card-modal-wrap .content-box .activity-log-wrap-box .log-box {
  display: flex;
  align-items: flex-start;
}
.overview-card-modal-wrap .content-box .activity-log-wrap-box .log-box .num {
  font-weight: 800;
  color: #5b6871;
  font-size: 1.45rem;
  transform: translateY(1rem);
}
.overview-card-modal-wrap .content-box .activity-log-wrap-box .log-box .img-wrap {
  margin-left: 3rem;
  display: grid;
  place-items: center;
}
.overview-card-modal-wrap .content-box .activity-log-wrap-box .log-box .img-wrap .img-box {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
}
.overview-card-modal-wrap .content-box .activity-log-wrap-box .log-box .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}
.overview-card-modal-wrap .content-box .activity-log-wrap-box .log-box .dash {
  width: 3rem;
  height: 0.15rem;
  background-color: #dde2e4;
  transform: translateY(1.8rem);
  margin: 0rem 0.8rem;
}
.overview-card-modal-wrap .content-box .activity-log-wrap-box .log-box .log-detail-wrap {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  color: #000000;
  transform: translateY(1rem);
}
.overview-card-modal-wrap .content-box .activity-log-wrap-box .log-box .log-detail-wrap .title {
  font-weight: 600;
  font-size: 1.6rem;
  text-transform: capitalize;
}
.overview-card-modal-wrap .content-box .activity-log-wrap-box .log-box .log-detail-wrap .initiator {
  font-size: 1.3rem;
  font-weight: 300;
  opacity: 0.6;
}
.overview-card-modal-wrap .content-box .activity-log-wrap-box .log-box .log-detail-wrap .time {
  font-size: 1.3rem;
  font-weight: 300;
  opacity: 0.6;
}
.overview-card-modal-wrap .content-box .activity-log-wrap-box .log-box .log-detail-wrap .date {
  font-size: 1.3rem;
  font-weight: 300;
  opacity: 0.6;
}
.overview-card-modal-wrap .content-box .activity-log-wrap-box .logo-box-border {
  position: relative;
}
.overview-card-modal-wrap .content-box .activity-log-wrap-box .logo-box-border > * {
  position: relative;
  z-index: 2;
}
.overview-card-modal-wrap .content-box .activity-log-wrap-box .logo-box-border::after {
  content: "";
  position: absolute;
  height: 100%;
  z-index: 1;
  top: 50%;
  transform: translateY(-12%);
  left: 15%;
  width: 0.1rem;
  background-color: #dde2e4;
}
.overview-card-modal-wrap .content-box .img-box {
  width: 3rem;
  height: 3rem;
}
.overview-card-modal-wrap .content-box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.overview-card-modal-wrap .content-box .btn-box {
  display: flex;
  justify-content: flex-end;
}
.overview-card-modal-wrap .content-box .img-input-wrap {
  display: flex;
}
.overview-card-modal-wrap .content-box .img-input-wrap .input-text {
  padding: 2rem;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.5rem;
  margin-left: 1.5rem;
  color: #84919a;
  flex: 1;
}
.overview-card-modal-wrap .content-box .img-input-wrap .input-text::-moz-placeholder {
  color: #84919a;
}
.overview-card-modal-wrap .content-box .img-input-wrap .input-text::placeholder {
  color: #84919a;
}
.overview-card-modal-wrap .content-box .img-input-wrap .input-text:focus {
  border: 0.1rem solid var(--border-color);
  outline: none;
}
.overview-card-modal-wrap .content-box .image-wrap-box {
  min-height: 10rem;
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-column-gap: 1rem;
  background-color: var(--bg-white-color);
  padding: 1rem;
}
.overview-card-modal-wrap .content-box .image-wrap-box .box-img {
  height: 10rem;
  overflow-y: hidden;
  cursor: pointer;
}
.overview-card-modal-wrap .content-box .image-wrap-box .box-img .img-box {
  width: 13rem;
  height: 13rem;
}
.overview-card-modal-wrap .content-box .image-wrap-box .box-img .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.overview-card-modal-wrap .content-box .title-drop-control-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.overview-card-modal-wrap .content-box .title-drop-control-box .title {
  color: var(--blue-color);
  font-size: 1.3rem;
}
.overview-card-modal-wrap .content-box .title-drop-control-box .icon-box {
  margin-left: auto;
  display: grid;
  place-items: center;
}
.overview-card-modal-wrap .content-box .title-drop-control-box .icon-box .icon {
  width: 2rem;
  height: 2rem;
  color: #b0babf;
}
.overview-card-modal-wrap .content-box .content-wrap {
  margin-top: 2rem;
  border-bottom: 0.1rem solid var(--border-color);
  padding-bottom: 1.5rem;
}
.overview-card-modal-wrap .content-box .content-wrap > *:not(:last-child) {
  margin-bottom: 2rem;
}
.overview-card-modal-wrap .content-box .content-wrap .three-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.overview-card-modal-wrap .content-box .content-wrap .two-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.overview-card-modal-wrap .content-box .content-wrap .box {
  font-size: 1.3rem;
}
.overview-card-modal-wrap .content-box .content-wrap .box .text {
  color: #5b6871;
  margin-bottom: 0.8rem;
}
.overview-card-modal-wrap .content-box .content-wrap .box .value {
  color: var(--black-color);
  font-weight: 600;
}

.box-file {
  background-color: var(--bg-white-color);
  box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 1.3rem;
  padding: 1rem 2rem;
}
.box-file .type span {
  font-weight: 800;
  text-transform: uppercase;
}
.box-file .name span {
  font-weight: 800;
}

.overview-card-modal-wrap-single {
  width: 80%;
  max-width: unset;
  margin: 2rem auto;
  height: unset;
  max-height: unset;
}

.top-box-title {
  display: flex;
  gap: 3rem;
  cursor: pointer;
}

.top-box-title-active {
  background-color: var(--bg-white-color);
  box-shadow: var(--box-shadow-two);
  position: relative;
  padding: 1rem;
}
.top-box-title-active::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 0.5rem;
  width: 70%;
  left: 50%;
  background-color: var(--blue-color);
  transform: translateX(-50%);
  border-radius: 5rem;
}

.emp-approval-modal {
  min-height: 30rem;
  width: 52rem;
  position: relative;
  overflow: hidden;
}
.emp-approval-modal .title {
  font-weight: 600;
  font-size: 1.5rem;
}
.emp-approval-modal .cancel-box {
  position: absolute;
  right: 0;
  margin-right: 2rem;
}
.emp-approval-modal .bottom-box-two {
  margin-top: 2rem;
}

.emp-activity-wrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-self: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  position: relative;
}
.emp-activity-wrap .hr-line {
  border-left: 0.1rem solid var(--border-color);
  height: 10rem;
  position: absolute;
  right: 19.5rem;
  top: 3rem;
}
.emp-activity-wrap .vr-line {
  border-top: 0.1rem solid var(--border-color);
  width: 2rem;
  position: absolute;
  right: 15.5rem;
  top: 1rem;
}
.emp-activity-wrap .activ-num {
  font-weight: 400;
  display: flex;
  position: absolute;
  right: 23rem;
}
.emp-activity-wrap .activ-img {
  position: absolute;
  right: 18rem;
  bottom: 7rem;
}
.emp-activity-wrap .activ-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--black-color);
}/*# sourceMappingURL=OverviewCardExpenseModal.css.map */