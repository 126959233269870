.zoho-modal-wrap {
  //   padding: 3rem;
  width: 45rem;
  .zoho-title {
    padding: 2rem;
    border-bottom: 0.1rem solid var(--border-color);
    font-weight: 600;
    color: var(--black-color);
    font-size: 1.8rem;
  }
  .zoho-text {
    padding: 2rem;
    padding-bottom: unset;
  }
  .input-wrap {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .label {
      color: #5b6871;
    }
    .span {
      color: #cc0905;
      margin-left: 0.4rem;
      display: inline-block;
      font-size: 1.7rem;
      transform: translateY(0.4rem);
    }

    .input {
      border: 0.1rem solid #dde2e4;
      border-radius: 0.8rem;
      padding: 1.3rem 1rem;
      outline: none;
      width: 100%;
      font-size: 1.4rem;
      margin-top: 0.5rem;
      font-size: 1.35rem;
      color: var(--black-shade-color);

      &::placeholder {
        font-size: 1.2rem;
        // text-transform: capitalize;
      }

      &:focus {
        border: 0.1rem solid var(--blue-color);
        outline: none;
      }
    }
    
    .input-active {
      border: 0.1rem solid var(--blue-color);
    }

    .btn-wrap {
      display: flex;
      justify-content: right;
      gap: 1.5rem;

      .cancel-btn {
        background-color: var(--white-color);
        color: var(--blue-color);
        border: 0.1rem solid var(--blue-color);
        position: relative;
      }
      // .connect-btn {
      //   padding: 4px 12px;
      //   outline: none;
      //   border: 0.1rem solid var(--blue-color);
      //   background-color: var(--blue-color);
      //   border-radius: 0.5rem;
      //   color: var(--blue-color);
      //   color: #ffff;
      //   transition: all 0.3s;
      //   cursor: pointer;
      //   &:hover {
      //     transform: translateY(-0.1rem);
      //   }
      // }
    }
  }
}
