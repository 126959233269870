.header-nav-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 1rem 4rem;
}
.header-nav-wrap .sidebar-toggle-icon {
  position: absolute;
  top: 50%;
  left: -2rem;
  transform: translate(-100%, -50%);
  transition: all 0.3s;
}
.header-nav-wrap .sidebar-toggle-icon .img-box {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
}
.header-nav-wrap .sidebar-toggle-icon .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.header-nav-wrap .sidebar-toggle-icon-reduce {
  left: 0;
  transform: translate(0%, -50%);
}
.header-nav-wrap .sidebar-toggle-icon-reduce .img-box {
  filter: brightness(0%);
  transform: rotate(180deg);
}
.header-nav-wrap .sidebar-toggle-icon-reduce-dark .img-box {
  filter: brightness(1000%);
}
.header-nav-wrap .back-box {
  background-color: var(--back-bg-color);
  width: 4.5rem;
  height: 3.5rem;
  border-radius: 1.5rem;
  display: grid;
  place-items: center;
  cursor: pointer;
  box-shadow: var(--box-shadow-one);
  margin-right: 2rem;
}
.header-nav-wrap .back-box:hover .img-box {
  transform: translateX(-0.2rem);
}
.header-nav-wrap .back-box .img-box {
  width: 3.5rem;
  height: 1.5rem;
  transition: all 0.3s;
}
.header-nav-wrap .back-box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}
.header-nav-wrap .page-title-box {
  color: var(--black-color);
  font-weight: 700;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.header-nav-wrap .page-title-box span {
  color: var(--blue-color);
  margin-left: 1.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  display: inline-block;
}
.header-nav-wrap .currency-select-box {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 0rem 1rem;
  border: 0.15rem solid var(--border-color);
  border-radius: 2rem;
  margin-right: 2.5rem;
}
.header-nav-wrap .currency-select-box .img-box {
  margin-right: 1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  overflow: hidden;
}
.header-nav-wrap .currency-select-box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}
.header-nav-wrap .currency-select-box .select-input {
  border: none;
  outline: none;
  color: var(--blue-color);
  width: 7rem;
}
.header-nav-wrap .currency-select-box select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  background-image: url("../../assets//drop-down-icon.svg");
  background-repeat: no-repeat; /* Add custom arrow */
  background-position: 95% center;
  background-size: 2rem;
  font-size: 1.4rem;
  font-family: "Outfit", sans-serif;
}
.header-nav-wrap .currency-select-box select option {
  font-weight: 500;
  font-size: 1.7rem;
}
.header-nav-wrap .currency-select-box .select-base-currency > * {
  background-color: var(--bg-white-color);
  color: #1d1d18;
}
.header-nav-wrap .currency-select-box .select-base-currency > *:nth-child(3) {
  border: none !important;
  min-width: 9rem;
  background-color: var(--bg-white-color);
  border-radius: 5rem !important;
}
.header-nav-wrap .currency-select-box .select-base-currency > *:nth-child(3) > *:nth-child(1) > *:nth-child(1) {
  color: var(--black-color);
}
.header-nav-wrap .currency-select-box .select-base-currency > *:nth-child(4) {
  right: 0;
  left: unset;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.header-nav-wrap .type-select-box {
  margin-right: 2.5rem;
}
.header-nav-wrap .type-select-box .select {
  font-size: 1.4rem;
  border: none;
  outline: none;
  width: 17rem;
}
.header-nav-wrap .type-select-box .select > * {
  background-color: var(--bg-white-color);
  color: #1d1d18;
}
.header-nav-wrap .type-select-box .select > *:nth-child(3) {
  border-radius: 1.2rem;
  background-color: var(--bg-white-color);
  border: 0.1rem solid var(--blue-color);
}
.header-nav-wrap .type-select-box .select > *:nth-child(3) > *:nth-child(1) > *:nth-child(1) {
  color: var(--black-color);
}
.header-nav-wrap .type-select-box .select option {
  font-weight: 500;
  font-size: 1.5rem;
}
.header-nav-wrap .notification-box {
  margin-right: 2.5rem;
  width: 3.5rem;
  height: 3.5rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  border: 0.1rem solid var(--border-color);
  position: relative;
  cursor: pointer;
}
.header-nav-wrap .notification-box span {
  position: absolute;
  top: 0.35rem;
  right: 0.65rem;
  width: 0.8rem;
  height: 0.8rem;
  background-color: #fa3f3f;
  border-radius: 50%;
}
.header-nav-wrap .notification-box .img-box {
  width: 2rem;
  height: 2rem;
}
.header-nav-wrap .notification-box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}
.header-nav-wrap .settings-box {
  margin-right: 2.5rem;
}
.header-nav-wrap .settings-box .img-box {
  width: 3rem;
  height: 3rem;
  cursor: pointer;
}
.header-nav-wrap .settings-box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}
.header-nav-wrap .profile-box {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.header-nav-wrap .profile-box .img-box {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  overflow: hidden;
  border-radius: 50%;
}
.header-nav-wrap .profile-box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}
.header-nav-wrap .profile-box .name {
  text-transform: capitalize;
  color: var(--black-color);
  margin-right: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
}
.header-nav-wrap .profile-box .icon {
  color: #b0babf;
  width: 1.5rem;
  height: 1.5rem;
  transform: translateY(0.2rem);
}
.header-nav-wrap .profile-box .profile-drop-box {
  position: absolute;
  top: 150%;
  right: 0;
  width: 22rem;
  height: 25rem;
  background-color: var(--bg-white-color);
  box-shadow: var(--box-shadow-two);
  transition: transform 0.3s linear;
  border-radius: 1.5rem;
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  transform: scale(0);
  transform-origin: top right;
}
.header-nav-wrap .profile-box .profile-drop-box .arrow-box {
  position: absolute;
  bottom: 100%;
  right: 1rem;
  width: 1.3rem;
  height: 1.3rem;
  background-color: var(--bg-white-color);
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
          clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  box-shadow: var(--box-shadow-two);
}
.header-nav-wrap .profile-box .profile-drop-box > *:not(:first-child) {
  padding: 0rem 2.5rem;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: var(--black-color);
  font-size: 1.5rem;
  font-weight: 500;
  border-top: 0.05rem solid var(--border-color);
}
.header-nav-wrap .profile-box .profile-drop-box > *:not(:first-child) .link {
  text-decoration: none;
  color: var(--black-color);
}
.header-nav-wrap .profile-box .profile-drop-box .img-text-box {
  background-color: #f6f8f9;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  display: flex;
  align-items: center;
  border-top: unset;
}
.header-nav-wrap .profile-box .profile-drop-box .img-text-box .img-box {
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 1rem;
  overflow: hidden;
  border-radius: 50%;
}
.header-nav-wrap .profile-box .profile-drop-box .img-text-box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}
.header-nav-wrap .profile-box .profile-drop-box .img-text-box .name {
  text-transform: capitalize;
  color: #5b6871;
  font-size: 1.5rem;
  font-weight: 500;
}
.header-nav-wrap .profile-box .profile-drop-box-show {
  transform: scale(1);
}/*# sourceMappingURL=HeaderNav.css.map */