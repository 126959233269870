.employee-draft-container {
  text-align: center;
  margin-top: 5rem;
  flex-direction: initial;
  margin: 0rem auto;
  margin-top: 5rem;
}

.employee-draft-container-two {
  /* background-color: red; */
  position: absolute;
  top: 10rem;
  left: 50%;
  transform: translateX(-50%);
}

.emp-draft-btn {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  /* background-color: blue; */
}
