.submit-all-draft-actual-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -1rem;
  padding: 0rem 4rem;
}

.employee-actual-expense-left-upload-wrap {
  margin-bottom: 0rem !important;
  border: 0.1rem dashed var(--border-color);
  border-radius: 1rem;
  background-color: #f6f8f9;
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem;
  width: 60rem;
}
.employee-actual-expense-left-upload-wrap .select-content {
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.employee-actual-expense-left-upload-wrap .select-content .label-upload-two {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.employee-actual-expense-left-upload-wrap .select-content .label-upload-two .plus-box {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 0.1rem dashed var(--blue-color);
  display: grid;
  place-items: center;
  color: var(--blue-color);
  margin-bottom: 1rem;
  cursor: pointer;
}
.employee-actual-expense-left-upload-wrap .select-content .label-upload-two .plus-box:hover .icon {
  transform: scale(1.2);
}
.employee-actual-expense-left-upload-wrap .select-content .label-upload-two .plus-box .icon {
  color: currentColor;
  transition: all 0.3s;
}
.employee-actual-expense-left-upload-wrap .select-content .upload-receipt {
  display: none;
}
.employee-actual-expense-left-upload-wrap .select-content .title {
  color: #5b6871;
}
.employee-actual-expense-left-upload-wrap .select-content .info {
  color: #84919a;
  opacity: 0.8;
  font-size: 1.2rem;
  width: 85%;
  margin-top: 2rem;
}
.employee-actual-expense-left-upload-wrap .select-content .label-upload .wrap {
  padding: 1.2rem 3rem;
  display: flex;
  align-items: center;
  background-color: var(--blue-color);
  color: #ffffff;
  border-radius: 0.8rem;
  cursor: pointer;
  transition: all 0.3s;
  outline: none;
  margin-top: 1rem;
}
.employee-actual-expense-left-upload-wrap .select-content .label-upload .wrap:hover {
  transform: translateY(-0.1rem);
}
.employee-actual-expense-left-upload-wrap .select-content .label-upload .wrap .icon {
  color: currentColor;
}
.employee-actual-expense-left-upload-wrap .select-content .label-upload .wrap .text {
  font-weight: 700;
  font-size: 1.35rem;
  margin-left: 0.6rem;
}
.employee-actual-expense-left-upload-wrap .selected-content-box {
  margin-top: auto;
  width: 100%;
  overflow-x: scroll;
  padding: 0rem 2rem;
  height: 15rem;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.employee-actual-expense-left-upload-wrap .selected-content-box::-webkit-scrollbar:vertical {
  display: none;
}
.employee-actual-expense-left-upload-wrap .selected-content-box::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.5rem;
}
.employee-actual-expense-left-upload-wrap .selected-content-box::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.employee-actual-expense-left-upload-wrap .selected-content-box::-webkit-scrollbar-thumb {
  background: var(--blue-color);
  border-radius: 30rem;
}
.employee-actual-expense-left-upload-wrap .selected-content-box::-webkit-scrollbar-thumb:hover {
  background: var(--blue-color);
}
.employee-actual-expense-left-upload-wrap .selected-content-box .wrap {
  display: grid;
  grid-template-rows: 1fr;
  justify-items: flex-start;
  width: 100%;
  flex: 1;
  padding-bottom: 1.5rem;
}
.employee-actual-expense-left-upload-wrap .selected-content-box .wrap > *:not(:last-child) {
  margin-right: 2rem;
}
.employee-actual-expense-left-upload-wrap .selected-content-box .wrap .box {
  width: 25rem;
  height: 13rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}
.employee-actual-expense-left-upload-wrap .selected-content-box .wrap .box-delete-file {
  position: absolute;
  right: 1rem;
  top: 0;
  padding: 0.5rem 0 2rem;
  cursor: pointer;
  border-radius: 50%;
}
.employee-actual-expense-left-upload-wrap .selected-content-box .wrap .box-delete {
  position: absolute;
  right: 7rem;
  padding: 0.5rem 0 2rem;
  cursor: pointer;
  border-radius: 50%;
}
.employee-actual-expense-left-upload-wrap .selected-content-box .wrap .box-file {
  background-color: var(--bg-white-color);
  box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 1.3rem;
  padding: 1rem 2rem;
}
.employee-actual-expense-left-upload-wrap .selected-content-box .wrap .box-file .type span {
  font-weight: 800;
  text-transform: uppercase;
}
.employee-actual-expense-left-upload-wrap .selected-content-box .wrap .box-file .name span {
  font-weight: 800;
}

.right-box-expense-actual-box-wrap {
  flex: 1;
}
.right-box-expense-actual-box-wrap > *:not(:last-child) {
  margin-bottom: 2rem;
}

.emp-shared-wrap .emp-shared-service-modal {
  display: flex;
  flex-direction: column;
  padding: 3rem;
}
.emp-shared-wrap .emp-shared-service-modal .title {
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 1rem;
}
.emp-shared-wrap .emp-shared-service-modal .note {
  color: var(--blue-color);
  margin-bottom: 3rem;
  margin-top: 0.3rem;
}
.emp-shared-wrap .emp-shared-service-modal .form-wrap {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.emp-shared-wrap .emp-shared-service-modal .form-wrap .form-group {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.emp-shared-wrap .emp-shared-service-modal .form-wrap .input {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 1.3rem 1rem;
  outline: none;
  width: 100%;
  font-size: 1.4rem;
  font-size: 1.35rem;
  color: var(--black-shade-color);
  color: var(--black-color);
  background-color: var(--bg-white-color);
}
.emp-shared-wrap .emp-shared-service-modal .form-wrap .input::-moz-placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.emp-shared-wrap .emp-shared-service-modal .form-wrap .input:-ms-input-placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.emp-shared-wrap .emp-shared-service-modal .form-wrap .input::placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.emp-shared-wrap .emp-shared-service-modal .form-wrap .input:focus {
  border: 0.1rem solid var(--blue-color);
  outline: none;
}
.emp-shared-wrap .emp-shared-service-modal .form-wrap .input-active {
  border: 0.1rem solid var(--blue-color);
}
.emp-shared-wrap .btn-wrap {
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
  margin-top: 2rem;
  border-top: 0.1rem solid var(--border-color);
  padding: 1rem;
  margin-bottom: 1rem;
}/*# sourceMappingURL=actualExpense.css.map */