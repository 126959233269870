.subscription-end-general-modal {
  width: 45rem;
  min-height: 45rem;
  background-color: #ffffff;
  display: grid;
  place-items: center;
}
.subscription-end-general-modal .wrap {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  text-align: center;
  align-items: center;
}
.subscription-end-general-modal .wrap .img-wrap {
  display: grid;
  place-items: center;
}
.subscription-end-general-modal .wrap .img-wrap .img-box {
  width: 17rem;
  height: 17rem;
}
.subscription-end-general-modal .wrap .img-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.subscription-end-general-modal .wrap .title {
  font-weight: 600;
  font-size: 2.5rem;
}
.subscription-end-general-modal .wrap .text {
  color: rgb(94, 99, 102);
}/*# sourceMappingURL=SubscriptionEndModal.css.map */