.admin-approval-settings-wrap {
  padding: 4rem;

  .subtitle-box {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }

    .text {
      cursor: pointer;
    }

    .active {
      font-weight: 600;
      color: var(--black-color);
    }
  }

  .title-text-box {
    margin-bottom: 2rem;
    border-bottom: 0.1rem solid var(--border-color);
    width: 80%;
    padding-bottom: 1.5rem;

    .title {
      font-weight: 700;
      font-size: 1.8rem;
      margin-bottom: 1rem;
      color: var(--black-color);
    }

    .text {
      font-size: 1.35rem;
      cursor: pointer;
    }
  }

  .title-btn-box {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    border-bottom: 0.1rem solid var(--border-color);
    padding-bottom: 1.5rem;
    margin-top: 5rem;

    .title-text-box {
      margin-bottom: 0rem;
      border-bottom: unset;
      // width: 80%;
      padding-bottom: unset;

      .title {
        font-weight: 700;
        font-size: 1.8rem;
        margin-bottom: 1rem;
        color: var(--black-color);
      }

      .text {
        font-size: 1.35rem;
      }
    }

    .btn-download-box {
      flex: 0 0 30%;
      align-self: flex-start;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .btn {
        padding: 0.8rem 2rem;
        border: none;
        outline: none;
        border-radius: 0.6rem;
        background-color: var(--bg-white-color);
        border: 0.1rem solid var(--blue-color);
        color: var(--blue-color);
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s;
        justify-content: center;
        text-transform: capitalize;
        font-size: 1.25rem;

        &:hover {
          transform: translateY(-0.15rem);
        }
      }

      .btn-save {
        background-color: #eceded;
        color: #c3c7ce;
        border: 0.1rem solid var(--border-color);
        margin-left: 3rem;
      }

      .btn-save-active {
        background-color: var(--blue-color);
        color: var((--white-color));
      }
    }
  }

  .list-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5rem;
    margin: 5rem 0rem 4rem 0rem;

    .item {
      height: 17rem;
      position: relative;
      background-color: var(--bg-white-color);
      padding: 2rem 3rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      box-shadow: var(--box-shadow-one);
      border-radius: 1rem;
      cursor: pointer;
      //   overflow: hidden;

      .title {
        font-size: 2rem;
        font-weight: 600;
        // margin-bottom: 1.5rem;
      }

      .text {
        font-size: 1.35rem;
      }

      .check-box {
        position: absolute;
        top: 2rem;
        right: 2rem;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        display: grid;
        place-items: center;
        border: 0.1rem solid var(--border-color);
        // padding: .7rem;

        .icon {
          color: var(--white-color);
          width: 1rem;
          height: 1rem;
        }
      }
    }

    .item-active {
      background-color: #f4faff;
      position: relative;

      & > * {
        position: relative;
        z-index: 3;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        border: 0.1rem solid var(--blue-color);
        border-radius: 1rem;
        // transition: ;
      }

      .check-box {
        border: unset;
        background-color: var(--blue-color);

        .icon {
          color: var(--white-color);
        }
      }
    }
  }

  .form-wrap {
    // background-color: red;
    // width: 50rem;

    .form-title {
      font-weight: 700;
      font-size: 1.45rem;
      margin-bottom: 1rem;
      color: var(--black-color);
    }

    .form {
      .wrapper {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 4rem;
        border-bottom: 0.1rem solid var(--border-color);
        padding-bottom: 3rem;

        .btn-set-up {
          align-self: flex-start;
          margin-top: 4rem;
          background-color: #eceded;
          padding: 1.2rem 4rem;
          color: #c3c7ce;
          text-align: center;
          cursor: pointer;
          border: 0.1rem solid var(--border-color);
          border-radius: 0.4rem;
          outline: none;
          transition: all 0.3s;
          font-weight: 500;

          &:hover {
            transform: translateY(-0.1rem);
          }
        }

        .btn-set-up-active {
          background-color: var(--blue-color);
          color: var(--white-color);
          border: none;
        }

        .box-title {
          color: var(--black-color);
          margin-bottom: 1rem;
          font-size: 1.5rem;
        }

        .small-text {
          font-size: 1.3rem;
        }

        .form-wrap {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          // grid-template-rows: repeat(2, 1fr);
          grid-gap: 3rem;

          .form-group {
            display: flex;
            flex-direction: column;

            select {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none; /* Remove default arrow */
              background-image: url("../../../assets/drop-down-icon.svg");
              background-repeat: no-repeat; /* Add custom arrow */
              background-position: 95% center;
              background-size: 2rem;
            }

            .label {
              margin-bottom: 0.4rem;
              display: flex;
              align-items: center;
              color: #5b6871;

              span {
                margin-left: 0.4rem;
                color: #cc0905;
                display: inline-block;
                font-size: 1.7rem;
                transform: translateY(0.4rem);
              }
            }

            .input {
              border: 0.1rem solid var(--border-color);
              border-radius: 0.8rem;
              padding: 1.3rem 1rem;
              outline: none;
              width: 100%;
              font-size: 1.4rem;
              //   background-color: transparent;
              font-size: 1.35rem;
              color: var(--black-color);
              background-color: var(--bg-white-color);

              &::placeholder {
                font-size: 1.2rem;
                text-transform: capitalize;
              }

              &:focus {
                border: 0.1rem solid var(--blue-color);
                outline: none;
              }
            }

            .input-active {
              border: 0.1rem solid var(--blue-color);
            }
          }
        }

        //   .form-wrap-six{
        //       grid-template-rows: repeat(3, 1fr);
        //   }

        .step-assign-box {
          // background-color: red;
          display: flex;
          flex-direction: column;

          .step-assign-line {
            display: grid;
            grid-template-columns: min-content 1fr 1fr;
            align-items: center;
            border-bottom: 0.1rem solid var(--border-color);
            padding-bottom: 1rem;
            grid-column-gap: 3rem;

            .text {
              display: flex;
              align-items: center;
              font-size: 1.25rem;
              text-transform: capitalize;
            }
          }
        }

        .removable-wrap {
          margin-top: 2rem;
          padding-top: 2rem;
          margin-bottom: 3rem;

          & > *:not(:first-child) {
            margin-top: 3rem;
          }

          .remove-group {
            width: 100%;
            // background: #cc0905;
            // display: grid;
            // grid-template-columns: min-content 2fr 1fr 1fr 1fr 5rem;
            // align-items: center;
            // grid-column-gap: 3rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            position: relative;

            &>*{
              position: relative;
              z-index: 3;
            }

            &::after {
              content: "";
              position: absolute;
              height: 110%;
              width: 0.05rem;
              background-color: #cccccc;
              top: 50%;
              left: 1.2rem;
              transform: translateY(-25%);  
              z-index: 2;
            }

            .top-wrap {
              width: 100%;
              display: flex;
              align-items: center;
              gap: 1rem;
              position: relative;
              z-index: 11;

              & > * {
                width: 40%;
              }

              .notify-user-box {
                margin-left: 2rem;
                display: flex;
                align-items: center;

                .notify-check {
                  display: none;

                  &:checked ~ .notify-label .circle::after {
                    display: block;
                  }
                }

                .notify-label {
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  gap: 1rem;

                  .circle {
                    position: relative;
                    border-radius: 50%;
                    width: 2.5rem;
                    height: 2.5rem;
                    border: 0.1rem solid var(--blue-color);

                    &::after {
                      content: "";
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      width: 60%;
                      height: 60%;
                      border-radius: 50%;
                      background-color: var(--blue-color);
                      display: none;
                    }
                  }
                }
              }
            }

            .bottom-wrap {
              width: 100%;
              display: grid;
              grid-template-columns: 1fr min-content repeat(2, 1fr);
              align-items: center;
              gap: 1.5rem;
              transform: translateX(4rem);
              position: relative;
              z-index: 10;

              & > * {
                width: 100%;
              }
            }

            .step {
              background-color: var(--blue-color);
              width: 2.5rem;
              height: 2.5rem;
              border-radius: 50%;
              // margin-right: 2rem;
              color: var(--white-color);
              display: grid;
              place-items: center;
              font-size: 1.2rem;
              font-weight: 600;
            }

            .cancel-box {
              width: 2.5rem;
              height: 2.5rem;
              border-radius: 50%;
              justify-self: flex-start;
              display: grid;
              place-items: center;
              border: 0.15rem solid #cc0905;
              cursor: pointer;

              .icon {
                color: #cc0905;
              }
            }
          }

          .remove-group-no-border{
            &::after{
              display: none;
            }
          }
        }

        .add-approval-box {
          align-self: flex-start;
          margin-top: 2rem;
          display: flex;
          align-items: center;
          cursor: pointer;
          color: var(--blue-color);

          .text {
            font-size: 1.3rem;
            font-weight: 500;
          }

          .icon {
            margin-right: 0.7rem;
            width: 1.3rem;
            height: 1.3rem;
          }
        }

        .content {
          margin-top: 2rem;

          & > *:not(:last-child) {
            margin-bottom: 2rem;
          }

          .check-label-box {
            // background-color: red;
            display: flex;
            align-items: center;

            .check {
              display: none;

              &:checked ~ .label span {
                background-color: var(--blue-color);
                border: unset;

                .icon {
                  visibility: visible;
                }
              }
            }

            .label {
              display: flex;
              align-items: center;
              font-size: 1.3rem;
              cursor: pointer;

              span {
                margin-right: 1.5rem;
                width: 1.7rem;
                height: 1.7rem;
                border-radius: 0.3rem;
                border: 0.1rem solid var(--border-color);
                display: grid;
                place-items: center;
                transition: all 0.3s;

                .icon {
                  width: 1rem;
                  height: 1rem;
                  color: var(--white-color);
                  visibility: hidden;
                }
              }
            }
          }
        }
      }
    }
  }
}
