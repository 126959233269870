.auth-layout-wrap {
  width: 100vw;
  min-height: 100vh;
  display: flex;
}
.auth-layout-wrap .left-box {
  flex: 0 0 50%;
  background-color: var(--bg-white-color);
  display: grid;
  place-items: center;
  box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 2;
  padding-top: 4rem;
}
.auth-layout-wrap .left-box .content-box {
  align-self: flex-start;
  width: 65%;
  display: flex;
  flex-direction: column;
  height: 75%;
  justify-content: space-between;
}
.auth-layout-wrap .left-box .content-box .logo-box {
  align-self: flex-start;
  cursor: pointer;
}
.auth-layout-wrap .left-box .content-box .list-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 7rem;
  overflow-x: hidden;
}
.auth-layout-wrap .left-box .content-box .list-box > *:not(:last-child) {
  margin-bottom: 4rem;
}
.auth-layout-wrap .left-box .content-box .list-box .item {
  display: flex;
  align-items: center;
}
.auth-layout-wrap .left-box .content-box .list-box .item span {
  flex: 0 0 10%;
}
.auth-layout-wrap .left-box .content-box .list-box .item span .icon {
  background-color: var(--blue-color);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  padding: 0.9rem;
  color: var(--white-color);
  box-shadow: var(--box-shadow-one);
}
.auth-layout-wrap .left-box .content-box .list-box .item .text {
  font-size: 1.6rem;
  flex: 1;
}
.auth-layout-wrap .left-box .content-box .list-box-anime > *:first-child {
  -webkit-animation: displayOne 1.5s cubic-bezier(0, 0, 1, 0) backwards;
          animation: displayOne 1.5s cubic-bezier(0, 0, 1, 0) backwards;
}
.auth-layout-wrap .left-box .content-box .list-box-anime > *:nth-child(2) {
  -webkit-animation: displayOne 1.5s 1.5s cubic-bezier(0, 0, 1, 0) backwards;
          animation: displayOne 1.5s 1.5s cubic-bezier(0, 0, 1, 0) backwards;
}
.auth-layout-wrap .left-box .content-box .list-box-anime > *:last-child {
  -webkit-animation: displayOne 1.5s 3s cubic-bezier(0, 0, 1, 0) backwards;
          animation: displayOne 1.5s 3s cubic-bezier(0, 0, 1, 0) backwards;
}
.auth-layout-wrap .left-box .content-box .copy-text {
  color: #84919a;
}
.auth-layout-wrap .right-box {
  flex: 1;
  background-color: #f8f9ff;
  display: grid;
  place-items: center;
  position: relative;
  z-index: 1;
}
.auth-layout-wrap .right-box .wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 4rem;
}
.auth-layout-wrap .right-box .wrap .route-to-sign-up span {
  cursor: pointer;
  color: var(--blue-color);
  font-weight: 600;
}
.auth-layout-wrap .right-box .wrap .content-wrap {
  flex: 0 0 85%;
  display: grid;
  place-items: center;
}
.auth-layout-wrap .right-box .wrap .content-box {
  width: 100%;
  min-height: 20rem;
  background-color: var(--white-color);
  box-shadow: var(--box-shadow-two);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  border: 0.1rem solid var(--border-color);
  padding: 3rem 4rem;
}
.auth-layout-wrap .right-box .wrap .content-box .title {
  font-weight: 700;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: var(--black-color);
}
.auth-layout-wrap .right-box .wrap .content-box .small-text {
  font-size: 1.3rem;
}
.auth-layout-wrap .right-box .wrap .content-box .btn-box {
  display: flex;
  padding: 1rem;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s;
}
.auth-layout-wrap .right-box .wrap .content-box .btn-box:hover {
  transform: translateY(-0.1rem);
}
.auth-layout-wrap .right-box .wrap .content-box .btn-box .img-box {
  width: 3rem;
  height: 3rem;
  margin-right: 1.5rem;
}
.auth-layout-wrap .right-box .wrap .content-box .btn-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.auth-layout-wrap .right-box .wrap .content-box .btn-box-first {
  margin-top: 4rem;
}
.auth-layout-wrap .right-box .wrap .content-box .or-box {
  margin: 2.5rem 0rem;
  display: grid;
  grid-template-columns: 1fr -webkit-min-content 1fr;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  grid-column-gap: 1.5rem;
}
.auth-layout-wrap .right-box .wrap .content-box .or-box > *:first-child, .auth-layout-wrap .right-box .wrap .content-box .or-box > *:last-child {
  height: 0.1rem;
  background-color: #dde2e4;
}
.auth-layout-wrap .right-box .wrap .content-box .or-box span {
  font-size: 1.3rem;
}
.auth-layout-wrap .right-box .wrap .content-box .term-privacy-box {
  margin-top: 3rem;
}
.auth-layout-wrap .right-box .wrap .content-box .term-privacy-box > *:first-child {
  margin-bottom: 0.5rem;
}
.auth-layout-wrap .right-box .wrap .content-box .term-privacy-box .text {
  color: #84919a;
  font-size: 1.35rem;
}
.auth-layout-wrap .right-box .wrap .content-box .term-privacy-box .text span {
  color: var(--blue-color);
  cursor: pointer;
}
.auth-layout-wrap .right-box .wrap .content-box .already-box {
  margin-top: 5rem;
}
.auth-layout-wrap .right-box .wrap .content-box .already-box .text {
  font-size: 1.4rem;
}
.auth-layout-wrap .right-box .wrap .content-box .already-box .text span {
  color: var(--blue-color);
  cursor: pointer;
  margin-left: 0.6rem;
  font-weight: 500;
}
.auth-layout-wrap .right-box .wrap .content-box .already-box-login {
  margin-top: 0rem;
}
.auth-layout-wrap .right-box .wrap .get-help-box {
  background-color: var(--blue-color);
  padding: 1.5rem 2rem;
  border-radius: 5rem;
  margin-left: auto;
  display: flex;
  align-items: center;
  color: var(--white-color);
  cursor: pointer;
  margin-top: auto;
  transition: transform 0.3s;
}
.auth-layout-wrap .right-box .wrap .get-help-box:hover {
  transform: translateY(-0.2rem);
}
.auth-layout-wrap .right-box .wrap .get-help-box .img-box {
  width: 2rem;
  height: 2rem;
}
.auth-layout-wrap .right-box .wrap .get-help-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.auth-layout-wrap .right-box .wrap .get-help-box .text {
  margin-right: 1rem;
  font-weight: 600;
}
.auth-layout-wrap .right-box .check-mail-wrap {
  width: 35rem;
  height: 100%;
  display: grid;
  place-items: center;
}
.auth-layout-wrap .right-box .check-mail-wrap .img-box {
  width: 12rem;
  height: 12rem;
}
.auth-layout-wrap .right-box .check-mail-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.auth-layout-wrap .right-box .check-mail-wrap .img-box-email {
  width: 8rem;
  height: 8rem;
  margin-bottom: 3rem;
}
.auth-layout-wrap .right-box .check-mail-wrap .title {
  font-weight: 700;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: var(--black-color);
}
.auth-layout-wrap .right-box .check-mail-wrap .small-text {
  font-size: 1.3rem;
}
.auth-layout-wrap .right-box .check-mail-wrap .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3rem 3rem;
  border-radius: 0.8rem;
  background-color: var(--blue-color);
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  color: var(--white-color);
  font-weight: 600;
  margin-top: 3rem;
}
.auth-layout-wrap .right-box .check-mail-wrap .btn:hover {
  transform: translateY(-0.15rem);
}
.auth-layout-wrap .right-box .check-mail-wrap .already-box {
  margin-top: 4rem;
}
.auth-layout-wrap .right-box .check-mail-wrap .already-box .text {
  font-size: 1.4rem;
}
.auth-layout-wrap .right-box .check-mail-wrap .already-box .text span {
  color: var(--blue-color);
  cursor: pointer;
  margin-left: 0.6rem;
  font-weight: 500;
}
.auth-layout-wrap .go-back-box {
  align-self: flex-start;
  display: flex;
  align-items: center;
  color: var(--blue-color);
  font-weight: 500;
  margin-top: 4rem;
  cursor: pointer;
  font-size: 1.3rem;
}
.auth-layout-wrap .go-back-box:hover .icon {
  transform: translateX(-0.3rem);
}
.auth-layout-wrap .go-back-box .text {
  margin-left: 0.5rem;
}
.auth-layout-wrap .go-back-box .icon {
  transition: all 0.3s;
}

@-webkit-keyframes displayOne {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes displayOne {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}/*# sourceMappingURL=AuthLayout.css.map */