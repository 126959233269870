// .employee-actual-expense-form-wrap {
//   background-color: green;
//   width: 100%;
// }

.submit-all-draft-actual-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -1rem;
  padding: 0rem 4rem;

  // .sub-btn {
  //   margin-right: 6rem !important;
  //   margin-bottom: unset;
  // }
}

.employee-actual-expense-left-upload-wrap {
  // background-color: red;
  margin-bottom: 0rem !important;
  border: 0.1rem dashed var(--border-color);
  border-radius: 1rem;
  background-color: #f6f8f9;
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem;
  width: 60rem;

  .select-content {
    margin-top: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .label-upload-two {
      display: flex;
      flex-direction: column;
      align-items: center;
      // background-color: red;

      .plus-box {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        border: 0.1rem dashed var(--blue-color);
        display: grid;
        place-items: center;
        color: var(--blue-color);
        margin-bottom: 1rem;
        cursor: pointer;

        &:hover .icon {
          transform: scale(1.2);
        }

        .icon {
          color: currentColor;
          transition: all 0.3s;
        }
      }
    }

    .upload-receipt {
      display: none;
    }

    .title {
      color: #5b6871;
    }

    .info {
      color: #84919a;
      opacity: 0.8;
      font-size: 1.2rem;
      width: 85%;
      margin-top: 2rem;
    }

    .label-upload {
      // background-color: red;
      .wrap {
        padding: 1.2rem 3rem;
        display: flex;
        align-items: center;
        background-color: var(--blue-color);
        color: #ffffff;
        border-radius: 0.8rem;
        cursor: pointer;
        transition: all 0.3s;
        outline: none;
        margin-top: 1rem;

        &:hover {
          transform: translateY(-0.1rem);
        }

        .icon {
          color: currentColor;
        }

        .text {
          font-weight: 700;
          font-size: 1.35rem;
          margin-left: 0.6rem;
        }
      }
    }

    // .btn-reuse {
    // }
  }

  .selected-content-box {
    margin-top: auto;
    width: 100%;
    overflow-x: scroll;
    padding: 0rem 2rem;
    height: 15rem;

    &::-webkit-scrollbar:vertical {
      display: none;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 0.6rem;
      height: 0.5rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none !important;
      background-color: transparent !important;
      // height: 1rem;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--blue-color);
      border-radius: 30rem;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--blue-color);
    }

    .wrap {
      display: grid;
      grid-template-rows: 1fr;
      justify-items: flex-start;
      width: 100%;
      flex: 1;
      // background-color: green;
      padding-bottom: 1.5rem;

      & > *:not(:last-child) {
        margin-right: 2rem;
      }
      .box {
        width: 25rem;
        height: 13rem;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
      }
      .box-delete-file {
        position: absolute;
        right: 1rem;
        top: 0;
        padding: 0.5rem 0 2rem;
        cursor: pointer;
        border-radius: 50%;
        // color: #fff;
      }
      .box-delete {
        position: absolute;
        right: 7rem;
        padding: 0.5rem 0 2rem;
        cursor: pointer;
        border-radius: 50%;
        // color: #fff;
      }

      .box-file {
        background-color: var(--bg-white-color);
        box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.05);
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        font-size: 1.3rem;
        padding: 1rem 2rem;

        .type {
          span {
            font-weight: 800;
            text-transform: uppercase;
          }
        }

        .name {
          span {
            font-weight: 800;
          }
        }
      }
    }
  }
}

.right-box-expense-actual-box-wrap {
  flex: 1;
  // background-color: red;

  & > *:not(:last-child) {
    margin-bottom: 2rem;
  }
}

// employee shared service modal
.emp-shared-wrap {
  .emp-shared-service-modal {
    display: flex;
    flex-direction: column;
    padding: 3rem;
    .title {
      font-weight: 600;
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    .note {
      color: var(--blue-color);
      margin-bottom: 3rem;
      margin-top: 0.3rem;
    }
    .form-wrap {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      .form-group {
        display: flex;
        flex-direction: column;
        gap: 2rem;
      }
      .input {
        border: 0.1rem solid var(--border-color);
        border-radius: 0.8rem;
        padding: 1.3rem 1rem;
        outline: none;
        width: 100%;
        font-size: 1.4rem;
        // text-transform: capitalize;
        font-size: 1.35rem;
        color: var(--black-shade-color);
        color: var(--black-color);
        background-color: var(--bg-white-color);

        &::placeholder {
          font-size: 1.2rem;
          text-transform: capitalize;
        }

        &:focus {
          border: 0.1rem solid var(--blue-color);
          outline: none;
        }
      }
      .input-active {
        border: 0.1rem solid var(--blue-color);
      }
    }
  }
  .btn-wrap {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    margin-top: 2rem;
    border-top: 0.1rem solid var(--border-color);
    padding: 1rem;
    margin-bottom: 1rem;
  }
}
