@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;500;600;800&family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
// @import '~bootstrap/scss/dropdown';

:root {
  --black-shade-color: #5b6871;
  --box-shadow-one: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.05);
  --box-shadow-two: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.07),
    -0.1rem -0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.07);
  --white-color: #ffffff;
  --black-color: #1d1d18;
  --blue-color: #004bff;
  --light-blue-color: #2b8cf7;
  --purple-gradient-color: linear-gradient(
    135deg,
    #3d5bf6 0%,
    #8598f9 0.01%,
    #2a2ea4 100%
  );
  --border-color: #dde2e4;
  --bg-color: #f8f9ff;
  --bg-white-color: #ffffff;
  --dark-fade-color: #e5e5e5;
  --blue-fade-color: #edf7ff;
  --back-bg-color: #f6f8f9;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-size: 1.4rem;
  font-family: "Outfit", sans-serif;
  font-weight: 300;
  /* overflow-x: hidden !important; */
  position: relative;
  width: 100vw;
  min-height: 100vh;
  overscroll-behavior: none;
  color: var(--black-color);
  overflow-x: hidden;
  // background-color: red;
}

// auto fill removal

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--bg-white-color) inset !important;
  -webkit-box-shadow: 0 0 0 30px var(--bg-white-color) inset !important;
  // background-color: var(--bg-white-color);
}

// blur styles start--------------

.blur-bg-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 120vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 5000;

  & > * {
    position: absolute;
    background-color: var(--white-color);
    min-width: 30rem;
    min-height: 30rem;
    border-radius: 1rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  background-image: url("../assets/drop-down-icon.svg");
  background-repeat: no-repeat; /* Add custom arrow */
  background-position: 95% center;
  background-size: 2rem;
}

// form submit global
.form-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.7rem 3rem;
  border-radius: 0.8rem;
  background-color: #eceded;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  color: #c3c7ce;
  font-weight: 600;
  margin-top: 0rem;

  &:hover {
    transform: translateY(-0.15rem);
  }
}

.form-submit-active {
  background-color: var(--blue-color);
  color: var(--white-color);
}

.select-wrap {
  & > *:nth-child(3) {
    // height: 4.3rem;
    padding: 0.3rem;
    border-radius: 0.7rem;

    & > * {
      background-color: var(--bg-white-color);
      color: #1d1d18;
    }

    background-color: var(--bg-white-color);

    & > *:nth-child(1) {
      & > *:nth-child(1) {
        color: #1d1d18;
      }
    }
  }
}

.select-wrap-multi {
  & > *:nth-child(3) {
    & > * {
      & > *:not(:last-child) {
        // color: #000000 !important;
        & > * {
          color: #ffffff !important;
        }
      }
    }
  }
}

.all-action-drop-down-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 20;

  .drop-down-box {
    position: absolute;
    top: 110%;
    right: 0;
    width: max-content;
    height: max-content;
    background-color: #ffffff;
    box-shadow: 0.1rem 0.1rem 8rem 0.1rem rgba(0, 0, 0, 0.05);
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
    animation: dropDownAnime .5s ease-in-out;
    border: 0.1rem solid #dde2e4;
    max-height: 35rem;
    overflow-y: scroll;

    &::-webkit-scrollbar{
      display: none;
    }

    &>*{
      width: 100%;
    }
  }

  .open-drop-down {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    border-radius: 0.6rem;
    color: #ffffff;
    // background-color: var(--blue-color);
    background-color: #746D6D;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    gap: 1rem;

    .text {
      color: currentColor;
    }

    .icon {
      color: currentColor;
    }

    &:hover {
      transform: translateY(-0.1rem);
    }
  }
}

@keyframes dropDownAnime {
  0% {
    opacity: 0.7;
    transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
