.expense-projection-main-wrap {
  padding: 3rem 4rem;
  display: flex;
  flex-direction: column;

  .title-arrow-select-box {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    .subtitle-box {
      // margin-bottom: 2rem;
      margin-right: auto;
      display: flex;
      align-items: center;

      & > *:not(:last-child) {
        margin-right: 0.5rem;
      }

      .text {
        cursor: pointer;
      }

      .active {
        font-weight: 600;
        color: var(--black-color);
      }
    }

    .select-wrap {
      display: flex;
      flex-direction: column;

      .label {
        margin-bottom: 0.4rem;
        color: #84919a;
        font-size: 1.3rem;
      }

      .select {
        font-size: 1.2rem;
        // color: #B0BABF;

        & > * {
          // background-color: var(--bg-white-color);
          color: #1d1d18;
          &:nth-child(3) {
            border: none !important;
            min-width: 9rem;
            background-color: var(--bg-white-color);
            border-radius: 0.7rem !important;
            border: 0.1rem solid var(--border-color);

            & > *:nth-child(1) {
              & > *:nth-child(1) {
                color: #1d1d18;
              }
            }
          }
        }
      }
    }
  }

  .total-info-detail-box {
    display: flex;
    align-items: center;
    gap: 4rem;
    // margin: 2rem 0rem;
    margin-bottom: 2rem;

    .detail-box {
      display: flex;
      flex-direction: column;

      .title {
        font-weight: 500;
        color: #84919a;
      }

      .value {
        font-weight: 800;
        font-size: 2.5rem;
        color: #5b6871;
      }
    }
  }

  .card-detail-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
    grid-gap: 3rem;
    grid-column-gap: 2rem;

    .card-box {
      padding: 3rem 2rem;
      border-radius: 1rem;
      background-color: var(--bg-white-color);
      box-shadow: var(--box-shadow-one);
      cursor: pointer;

      .name {
        margin-bottom: 1.5rem;
        font-size: 1.3rem;
      }

      .value {
        font-weight: 600;
        font-size: 2.2rem;
        color: var(--black-color);
      }
    }
  }

  .filter-approve-btn-box {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    // margin-bottom: -3rem;

    .notify-bell-box {
      .img-box {
        width: 4rem;
        height: 4rem;
        margin-right: 2rem;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
          transform: scale(1.05);
        }

        .img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }

    .select-wrap {
      margin-right: auto;

      // .label {
      //     margin-bottom: 0.4rem;
      //     color: #1D1D18;
      //     // font-size: 1.rem;
      //     font-weight: 600;
      //   }

      .select {
        font-size: 1.2rem;
        // color: #B0BABF;
        z-index: 2;
        // width: 20rem;

        & > * {
          // background-color: var(--bg-white-color);
          color: #1d1d18;
          &:nth-child(3) {
            border: none !important;
            min-width: 9rem;
            background-color: var(--bg-white-color);
            border-radius: 0.7rem !important;

            & > *:nth-child(1) {
              & > *:nth-child(1) {
                color: #1d1d18;
              }
            }
          }

          &:nth-child(4) {
            width: max-content;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}
