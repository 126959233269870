.welcome-box-wrap {
  transform: translate(50%, -60%);
  border-radius: 1.5rem;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  width: 35rem;
  padding-top: 4rem;
  -webkit-animation: moveUpWelcome 0.7s linear backwards;
          animation: moveUpWelcome 0.7s linear backwards;
  background-color: var(--bg-white-color);
}
.welcome-box-wrap .arrow-box {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
}
.welcome-box-wrap .arrow-box .icon {
  color: var(--black-color);
}
.welcome-box-wrap .title {
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: var(--blue-color);
  font-size: 1.8rem;
}
.welcome-box-wrap .text {
  color: #84919A;
  font-size: 1.1rem;
}
.welcome-box-wrap .progress-box {
  display: flex;
  align-items: center;
  margin: 1.5rem 0rem;
}
.welcome-box-wrap .progress-box .percentage {
  flex: 0 0 5%;
  margin-right: 1rem;
  color: var(--black-color);
  font-weight: 600;
}
.welcome-box-wrap .list-box {
  display: flex;
  flex-direction: column;
}
.welcome-box-wrap .list-box .item {
  padding: 1.5rem 0rem;
  border-bottom: 0.1rem solid var(--border-color);
  display: flex;
  align-items: center;
}
.welcome-box-wrap .list-box .check-box {
  margin-right: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  border: 0.1rem solid var(--border-color);
}
.welcome-box-wrap .list-box .check-box .icon {
  color: var(--white-color);
  width: 1rem;
  height: 1rem;
}
.welcome-box-wrap .list-box .check-box-active {
  border: unset;
  background-color: var(--blue-color);
}
.welcome-box-wrap .list-box .check-box-active .icon {
  color: #ffffff;
}
.welcome-box-wrap .list-box .link {
  text-decoration: none;
  color: var(--black-color);
  font-size: 1.4rem;
}
.welcome-box-wrap .dismiss {
  color: var(--blue-color);
  text-transform: capitalize;
  margin-top: 1.5rem;
  cursor: pointer;
  align-self: flex-start;
  font-size: 1.5rem;
}

@-webkit-keyframes moveUpWelcome {
  0% {
    transform: translate(50%, -40%);
    opacity: 0;
    filter: blur(0.05rem);
  }
  100% {
    transform: translate(50%, -60%);
    opacity: 1;
    filter: blur(0rem);
  }
}

@keyframes moveUpWelcome {
  0% {
    transform: translate(50%, -40%);
    opacity: 0;
    filter: blur(0.05rem);
  }
  100% {
    transform: translate(50%, -60%);
    opacity: 1;
    filter: blur(0rem);
  }
}/*# sourceMappingURL=WelcomeBox.css.map */